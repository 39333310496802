
const template = `
<!DOCTYPE HTML
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office">

<head>

  <!--[if gte mso 9]>
  <xml>
    <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
  </xml>
  <![endif]-->

  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="x-apple-disable-message-reformatting">
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->

  <!-- Your title goes here -->
  <title>Newsletter</title>
  <!-- End title -->

  <!-- Start stylesheet -->
  <style type="text/css">
    a,
    a[href],
    a:hover,
    a:link,
    a:visited {
      /* This is the link colour */
      text-decoration: none !important;
      color: #0000EE;
    }

    .link {
      text-decoration: underline !important;
    }

    p,
    p:visited {
      /* Fallback paragraph style */
      font-size: 15px;
      line-height: 24px;
      font-family: 'Helvetica', Arial, sans-serif;
      font-weight: 300;
      text-decoration: none;
      color: #000000;
    }

    h1 {
      /* Fallback heading style */
      font-size: 22px;
      line-height: 24px;
      font-family: 'Helvetica', Arial, sans-serif;
      font-weight: normal;
      text-decoration: none;
      color: #000000;
    }

    .ExternalClass p,
    .ExternalClass span,
    .ExternalClass font,
    .ExternalClass td {
      line-height: 100%;
    }

    .ExternalClass {
      width: 100%;
    }
  </style>
</head>

<body
  style="text-align: center; margin: 0; padding-top: 10px; padding-bottom: 10px; padding-left: 0; padding-right: 0; -webkit-text-size-adjust: 100%;background-color: #f2f4f6; color: #000000"
  align="center">
  <div style="text-align: center;">

    <table align="center"
      style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #134361;"
      width="600">
      <tbody>
        <tr>
          <td
            style="width: 596px; vertical-align: top; padding-left: 0; padding-right: 0; padding-top: 15px; padding-bottom: 15px;"
            width="500">

            <!-- Your logo is here -->
            <img style="width: 180px; max-width: 180px;; text-align: center; color: #ffffff;" alt="Logo"
              src="https://hub.itinera-talentcoaching.be/img/logo.png" align="center" width="180">

          </td>
        </tr>
      </tbody>
    </table>

    <table align="center"
      style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;"
      width="600">
      <tbody>
        <tr>
          <td
            style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 30px; padding-bottom: 40px;"
            width="596">

            <h1
              style="font-size: 20px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 600; text-decoration: none; color: #000000;">
              {{title}}</h1>

            <p
              style="font-size: 15px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #919293;">
              {{hello}} {{name}}</p>
            <p
              style="font-size: 15px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #919293;">
             {{text}}

            </p>

            <!-- Start button (You can change the background colour by the hex code below) -->
            <a href="{{link}}" target="_blank"
              style="background-color: #000000; font-size: 15px; line-height: 22px; font-family: 'Helvetica', Arial, sans-serif; font-weight: normal; text-decoration: none; padding: 12px 15px; color: #ffffff; border-radius: 5px; display: inline-block; mso-padding-alt: 0;">
              <!--[if mso]>
                  <i style="letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 30pt;">&nbsp;</i>
                <![endif]-->

              <span style="mso-text-raise: 15pt; color: #ffffff;">{{callToAction}}</span>
              <!--[if mso]>
                  <i style="letter-spacing: 25px; mso-font-width: -100%;">&nbsp;</i>
                <![endif]-->
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <table align="center"
      style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #000000;"
      width="600">
      <tbody>
        <tr>
          <td
            style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 30px; padding-bottom: 30px;"
            width="596">

            <p
              style="font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
              {{question}} info@itinera-talentcoaching.be
            </p>

            <p
              style="margin-bottom: 0; font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
              <a target="_blank" style="text-decoration: underline; color: #ffffff;"
                href="https://itinera-talentcoaching.be/">
                itinera-talentcoaching.be
              </a>
            </p>

          </td>
        </tr>
      </tbody>
    </table>
  </div>

</body>

</html>
`
export const getMailTemplate = (data: Record<string, string>) => {
    let text = template;
    for(const key in data) {
        text = text.replace(`{{${key}}}`, data[key])
    }
    return text;
}