
import { User } from "@/store/user/types";
import { Component, Vue, Watch } from "vue-property-decorator";

import { ROOT_ACTIONS } from "@/store/actions";
import { Mail } from "@/types";

import { firestore } from '@/firebase';
import { doc, getDoc, updateDoc, getDocs, collection, query, where, deleteDoc, addDoc, onSnapshot, setDoc, orderBy } from "firebase/firestore";
import { USER_GETTERS } from "@/store/user/getters";
import * as emails from '@/bin/emails'
import { format, formatDistance } from "date-fns";
import { nlBE } from "date-fns/locale";
import { getMailTemplate } from '../../../../functions/src/template'

@Component({
  components: {
    ConfirmationDialog: () => import('@/components/dialogs/ConfirmationDialog.vue'),
    AddUserPasswordDialog: () => import('@/components/dialogs/AddUserPassword.vue')
  }
})
export default class Overview extends Vue {
  user: null | User = null;
  userCopy: null | User = null;
  mails = [] as Mail[];

  userRef = doc(firestore, "users", this.$route.params.userId);
  programsCollection = collection(firestore, 'programs');
  mailsCollection = collection(firestore, 'mails');

  languages = [
    {
      text: 'Nederlands',
      value: 'nl-be'
    },
    {
      text: 'Frans',
      value: 'fr'
    }
  ]

  loading = {
    update: false,
    deleteUser: false,
    impersonate: false,
    resetPassword: false,
    resendInvitation: false,
  };

  dialogs = {
    addPassword: false,
    deleteUser: false,
  }

  @Watch("user")
  onUserChange() {
    if (this.user) this.userCopy = { ...this.user };
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Gebruikers', target: '/admin/users' }, { text: `${this.user?.firstname} ${this.user?.lastname}`, target: `/admin/users/${this.user?.id}` }])
  }

  async mounted() {
    const userSnap = await getDoc(this.userRef);

    this.user = {
      id: userSnap.id,
      ...userSnap.data()
    } as User;

    this.fetchMails();
  }


  async sendPasswordResetEmail() {
    try {
      this.loading.resetPassword = true;
      if (this.user?.email) {
        await setDoc(doc(firestore, 'password-reset', this.user.email), {})
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: "info",
          text: "Reset aangevraagd",
        });
      }
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    } finally {
      this.loading.resetPassword = false;
    }
  }

  async update() {
    try {
      this.loading.update = true;
      if (this.userCopy) {
        await updateDoc(this.userRef, this.userCopy as any);
      }

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "info",
        text: "Gebruiker bijgewerkt",
      });
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    } finally {
      this.loading.update = false;
      Object.assign(this.user as User, this.userCopy);
    }
  }

  async deleteUser() {
    try {
      this.loading.deleteUser = true;
      const programs = await getDocs(query(this.programsCollection, where('participant', '==', this.$route.params.userId), orderBy('date', 'asc')));

      for (let program of programs.docs) {
        await deleteDoc(program.ref);
      }

      await deleteDoc(doc(firestore, 'users', this.$route.params.userId));

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "info",
        text: "Gebruiker verwijderd",
      });
      this.$router.push("/admin/users");
    } catch (error) {
      console.log(error)
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    } finally {
      this.loading.deleteUser = false;
    }
  }

  async resendInvite() {
    try {
      if (!this.user) return
      this.loading.resendInvitation = true;

      const user = this.user;
      const template = getMailTemplate({
        hello: user.language === 'nl-be' ? 'Dag' : 'Bonjour',
        name: user.firstname,
        link: "https://hub.itinera-talentcoaching.be",
        title: user.language === 'nl-be' ? 'Itinera Hub: activeer uw account' : 'Itinera Hub: activez votre compte',
        text: user.language === 'nl-be' ? 'Er werd een account aangemaakt voor u op de Itinera Hub, Hiermee kan u alle outplacementactiviteiten opvolgen' : 'Un compte a été créé pour vous sur le Hub Itinera. Vous pouvez ainsi suivre toutes les activités d\'outplacement.',
        callToAction: user.language === 'nl-be' ? 'Naar Itinera Hub' : 'Confirmer',
        question: user.language === 'nl-be' ? 'Heb je vragen?' : 'Avez-vous des questions?',
      })

      await addDoc(collection(firestore, 'mails'), {
        to: [user.email],
        from: 'no-reply@itinera-talentcoaching.be',
        cc: [],
        subject: user.language === 'nl-be' ? 'Itinera Hub: activeer uw account' : 'Itinera Hub: activez votre compte',
        body: template,
        sent: false,
        date: new Date()
      })
      await this.fetchMails();

      this.loading.resendInvitation = false;

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: 'Uitnodiging verzonden',
      });
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    }
  }

  async fetchMails() {
    const toEmails = await getDocs(query(this.mailsCollection, where('to', 'array-contains', this.user?.email), orderBy('date', 'desc')));
    const ccEmails = await getDocs(query(this.mailsCollection, where('cc', 'array-contains', this.user?.email), orderBy('date', 'desc')));
    const bccEmails = await getDocs(query(this.mailsCollection, where('bcc', 'array-contains', this.user?.email), orderBy('date', 'desc')));

    this.mails = [...toEmails.docs, ...ccEmails.docs, ...bccEmails.docs].map(document => document.data() as Mail)
  }

  formatDate(date: Date): string {
    return `${format(date, 'dd/MM/yyyy HH:mm')} (${formatDistance(date, new Date(), { addSuffix: true, locale: nlBE })})`
  }
}
